<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">退款订单列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc operationControlWidth"
          style="align-items: flex-start"
        >
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="订单日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 6rem">订单日期:</span>
              <el-date-picker
                clearable
                style="width: 260px"
                size="small"
                format="yyyy-MM-dd"
                v-model="searchData.orderTme"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="支付时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 6rem">支付日期:</span>
              <el-date-picker
                clearable
                style="width: 260px"
                size="small"
                format="yyyy-MM-dd"
                v-model="searchData.paymentTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
          </div>
          <div class="searchbox">
            <div title="退款日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 6rem">退款日期:</span>
              <el-date-picker
                clearable
                style="width: 260px"
                size="small"
                format="yyyy-MM-dd"
                v-model="searchData.refundTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="支付方式" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 5rem">支付方式:</span>
              <el-select
                size="small"
                v-model="searchData.paymentMethod"
                placeholder="请选择支付方式"
                clearable
              >
                <el-option
                  v-for="item in paymentMethodList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel">单位名称:</span>
              <el-select
                size="small"
                v-model="searchData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
            >
              查询
            </el-button>
            <el-button class="bgc-bv" round @click="handleExport()">
              导出
            </el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="退款订单编号"
                align="left"
                show-overflow-tooltip
                prop="refundSn"
                min-width="150px"
              />
              <el-table-column
                label="订单编号"
                align="left"
                show-overflow-tooltip
                prop="orderSn"
                min-width="150px"
              />

              <el-table-column
                label="购买单位"
                align="left"
                show-overflow-tooltip
                prop="compName"
                min-width="150px"
              />
              <el-table-column
                label="支付方式"
                align="right"
                show-overflow-tooltip
                prop="paymentMethod"
                width="100px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("PAYMENT_METHOD", scope.row.paymentMethod)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="订单金额（元）"
                align="right"
                show-overflow-tooltip
                prop="orderMoney"
                width="120px"
              />

              <el-table-column
                label="订单时间"
                align="right"
                show-overflow-tooltip
                width="180px"
                prop="createTime"
              />
              <el-table-column
                label="支付时间"
                align="right"
                show-overflow-tooltip
                width="180px"
                prop="payTime"
              />

              <el-table-column
                label="退款时间"
                align="right"
                show-overflow-tooltip
                width="200px"
                prop="refundTime"
              />
              <el-table-column
                label="退款金额"
                align="right"
                show-overflow-tooltip
                prop="refundAmount"
              />
              <el-table-column
                label="退款状态"
                align="right"
                show-overflow-tooltip
                prop="orderStatus"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("ORDER_STATUS", scope.row.orderStatus) }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="180px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="seeCurriculumInfo(scope.row)"
                    >查看订单</el-button
                  >
                  <el-button
                    v-if="scope.row.paymentMethod == '5'"
                    :disabled="scope.row.orderStatus == 4"
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="cancellationOfOrder(scope.row)"
                    >确认已退款</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
      <el-dialog
        title="查看退款订单"
        :visible.sync="seeInfoldVisible"
        :before-close="closeSeeInfologVisible"
        width="1000px"
      >
        <h3 class="h3">退款信息：</h3>
        <el-row>
          <el-col :span="24"
            ><div class="grid-content bg-purple">
              <span>退款订单编号：</span>
              <span>{{ infologData.refundSn }}</span>
            </div></el-col
          >
          <el-col :span="24"
            ><div class="grid-content bg-purple">
              <span>申请退款时间：</span>
              <span>{{ infologData.refundTime }}</span>
            </div></el-col
          >
          <el-col :span="24"
            ><div class="grid-content bg-purple">
              <span>申请退款金额：</span>
              <span>{{
                infologData.refundPrice == 0 ? "--" : infologData.refundPrice
              }}</span>
            </div></el-col
          >
          <el-col :span="24"
            ><div class="grid-content bg-purple">
              <span>退款状态：</span>
              <span>{{
                $setDictionary("ORDER_STATUS", infologData.orderStatus)
              }}</span>
            </div></el-col
          >
        </el-row>
        <h3 class="h3">订单信息：</h3>
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <span>订单编号：</span>
              <span>{{ infologData.orderSn }}</span>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <span>支付时间：</span>
              <span>{{ infologData.payTime }}</span>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <span>订单金额：</span>
              <span>{{ infologData.orderPrice }}</span>
            </div></el-col
          >

          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <span>支付方式：</span>
              <span>{{
                $setDictionary("PAYMENT_METHOD", infologData.paymentMethod)
              }}</span>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <span>课程名称：</span>
              <span>{{ infologData.productName }}</span>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <span>数量：</span>
              <span>{{ infologData.productCount }}</span>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <span>单价：</span>
              <span>{{ infologData.productPrice }}</span>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <span>金额：</span>
              <span>{{ infologData.totalPrice }}</span>
            </div></el-col
          >
        </el-row>
        <h3 class="h3">购买单位信息：</h3>
        <el-row>
          <el-col :span="24"
            ><div class="grid-content bg-purple">
              <span>单位名称：</span>
              <span>{{ infologData.compName }}</span>
            </div></el-col
          >
          <el-col :span="24"
            ><div class="grid-content bg-purple">
              <span>联系人：</span>
              <span>{{ infologData.contactName }}</span>
            </div></el-col
          >
          <el-col :span="24"
            ><div class="grid-content bg-purple">
              <span>联系电话：</span>
              <span>{{ infologData.contactTel }}</span>
            </div></el-col
          >
        </el-row>
        <div slot="footer" class="dialog-footer flexcc">
          <el-button class="bgc-bv" size="small" @click="closeSeeInfologVisible"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <!-- 确认退款 -->
      <el-dialog
        title="确认退款时间"
        :visible.sync="dialogFormVisible"
        @close="cancle('ruleForm')"
      >
        <el-form :model="ruleForm" ref="ruleForm" :rules="rules">
          <el-form-item label="财务退款日期:" label-width="130px" prop="date">
            <el-date-picker
              v-model="ruleForm.date"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancle('ruleForm')">取 消</el-button>
          <el-button
            type="primary"
            @click="dialogFormVisibleSure('ruleForm')"
            class="bgc-bv"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";

export default {
  name: "evaluate/statementList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      searchData: {
        orderTme: "", // 订单时间
        paymentTime: "", // 支付时间
        compId: "", // 单位名称
        paymentMethod: "", // 支付方式
        refundTime: "", //退款日期
      },
      CompanyList: [],
      paymentMethodList: [],
      // 查看订单详情 - 弹框
      seeInfoldVisible: false,
      infologData: {},
      //确认退款日期
      dialogFormVisible: false,
      ruleForm: {
        date: "",
      },
      rules: {
        date: {
          required: true,
          message: "请选择日期",
          trigger: "change",
        },
      },
      refundId: "",
      orderMoney: "",
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {
    this.getPaymentMethodList();
    this.getTableHeight();
  },
  methods: {
    handleExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {};

          if (this.searchData.compId) {
            params.compId = this.searchData.compId;
          }
          if (this.searchData.paymentMethod) {
            params.paymentMethod = this.searchData.paymentMethod;
          }
          if (this.searchData.paymentTime) {
            params.payBeginTime = this.searchData.paymentTime[0];
            params.payEndTime = this.searchData.paymentTime[1];
          }
          if (this.searchData.orderTme) {
            params.orderCreateBeginTime = this.searchData.orderTme[0];
            params.orderCreateEndTime = this.searchData.orderTme[1];
          }
          if (this.searchData.refundTime) {
            params.refundBeginTime = this.searchData.refundTime[0];
            params.refundEndTime = this.searchData.refundTime[1];
          }
          
          this.$post(
            "/order/drp-order-main/refund/export",
            params,
            3000,
            false,
            2
          ).then((res) => {
            if (res.status == "0") {
              let list = res.data;
              if (!this.downloadItems.includes(list.taskId)) {
                this.$store.dispatch("pushDownloadItems", list.taskId);
              } else {
                this.$message.warning(
                  "[" + list.fileName + "]已经申请下载,请耐心等待"
                );
              }
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },

    // 获取 - 单位名称 - 下拉数据
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取 - 支付方式 - 下拉数据
    getPaymentMethodList() {
      const evaluateCertPaymentList = this.$setDictionary(
        "PAYMENT_METHOD",
        "list"
      );
      for (const key in evaluateCertPaymentList) {
        this.paymentMethodList.push({
          value: key,
          label: evaluateCertPaymentList[key],
        });
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (this.searchData.paymentMethod) {
        params.paymentMethod = this.searchData.paymentMethod;
      }
      if (this.searchData.paymentTime) {
        params.payBeginTime = this.searchData.paymentTime[0];
        params.payEndTime = this.searchData.paymentTime[1];
      }
      if (this.searchData.orderTme) {
        params.orderCreateBeginTime = this.searchData.orderTme[0];
        params.orderCreateEndTime = this.searchData.orderTme[1];
      }
      if (this.searchData.refundTime) {
        params.refundBeginTime = this.searchData.refundTime[0];
        params.refundEndTime = this.searchData.refundTime[1];
      }
      this.doFetch(
        {
          url: "/order/drp-order-main/refund/query",
          params,
          pageNum,
        },
        true,
        2
      );
      // this.getTotal();
    },
    // 查看订单 - 弹框展示
    seeCurriculumInfo(row) {
      this.$post(
        "/order/drp-order-main/refund/detail",
        {
          refundId: row.refundId,
        },
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == "0") {
          this.seeInfoldVisible = true;
          this.$set(this, "infologData", res.data);
          if (res.data.fileType == "PDF" || res.data.fileType == "pdf") {
            this.$nextTick(() => {
              pdf.embed(res.data.voucherUrl, "#pdf-content2", {
                pdfOpenParams: {
                  scrollbars: "0",
                  toolbar: "0",
                  statusbar: "1",
                },
              });
            });
          }
        }
      });
    },
    // 取消 - 查看订单 - 关闭弹框
    closeSeeInfologVisible() {
      this.seeInfoldVisible = false;
    },
    //确认付款
    cancellationOfOrder(row) {
      //弹框
      this.refundId = row.refundId;
      this.orderMoney = row.orderMoney;
      this.dialogFormVisible = true;
    },
    dialogFormVisibleSure(formName) {
      let params = {
        refundId: this.refundId,
        refundAmount: this.orderMoney,
      };

      this.$refs[formName].validate((valid) => {
        if (valid) {
          params.refundTime = this.ruleForm.date;
          this.$post("/transfer/pay/refund/ensure", params, 3000, true, 2).then(
            (res) => {
              if (res.status == "0") {
                this.$message({
                  message: "已确认退款",
                  type: "success",
                });
                this.dialogFormVisible = false;
                this.ruleForm.date = "";
                this.getData(-1);
              }
            }
          );
        }
      });
    },
    /* 弹框取消 */
    cancle(formName) {
      this.dialogFormVisible = false;
      this.ruleForm.date = "";
      this.$refs[formName].resetFields();
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.h3 {
  margin-bottom: 20px;
}
.el-row {
  margin-bottom: 20px;
}
</style>
